import _keyBy from "lodash/keyBy";
import _mapValues from "lodash/mapValues";
import "core-js/modules/es.array.push.js";
import common from '@mixins/common';
import { mapGetters } from 'vuex';
import * as assetsApi from '@api/assets';
import imageCompression from 'browser-image-compression';
const defaultProduct = {
  outSpaceFullCode: '',
  outSpaceName: '',
  stockId: '',
  productId: '',
  productNo: '',
  productProperty: 0,
  productName: '',
  standard: '',
  unit: '',
  actPrice: 0,
  disposePrice: '',
  stockNum: '',
  disposeNum: 1,
  disposeReason: '',
  disposeReasonText: ''
};
const productPropertyList = [{
  label: "易耗品",
  value: 1
}, {
  label: "低值品",
  value: 2
}, {
  label: "固定资产",
  value: 3
}];
const productPropertyMap = _mapValues(_keyBy(productPropertyList, i => i.value), j => j.label);
const disposeReasonList = [{
  label: "资产报废",
  value: 1
}, {
  label: "冲销处理",
  value: 2
}, {
  label: "闲置变卖",
  value: 3
}];
const disposeReasonMap = _mapValues(_keyBy(disposeReasonList, i => i.value), j => j.label);
export default {
  name: 'AssetsDispose',
  mixins: [common],
  computed: {
    ...mapGetters('user', ['userInfo', 'projectInfo']),
    disposeTotalPrice() {
      if (this.productList && this.productList.length) {
        let sum = 0;
        for (let product of this.productList) {
          try {
            sum += Math.round(parseFloat(product.actPrice) * 100 * product.disposeNum);
          } catch (e) {}
        }
        return sum / 100 + '';
      } else {
        return '';
      }
    }
  },
  data() {
    return {
      disposePerson: '',
      // disposeTotalPrice:'',
      remark: '',
      projectId: '',
      projectName: '',
      productList: [{
        ...defaultProduct
      }],
      disposeReasonListShow: false,
      disposeReasonList,
      currentProductIndex: -1,
      productPropertyListShow: false,
      productPropertyList,
      productPropertyMap,
      productListShow: false,
      productListGets: [],
      productListSearchKeyword: '',
      productListSearchPageNum: 1,
      productListSearchPageSize: 10,
      productListSearchTotal: 0
    };
  },
  mounted() {
    this.disposePerson = this.userInfo.userName;
    this.projectId = this.projectInfo.dcProjectId;
    this.projectName = this.projectInfo.projectName;
  },
  methods: {
    onDisposeReasonShow(index) {
      this.disposeReasonListShow = true;
      this.currentProductIndex = index;
      this.$nextTick(() => {
        let value = this.productList[index].disposeReason;
        let selectIndex = this.disposeReasonList.findIndex(i => i.value === value);
        this.$refs.disposeReasonList.setIndexes([selectIndex]);
      });
    },
    onDisposeReasonSelect(item) {
      this.productList[this.currentProductIndex].disposeReason = item.value;
      this.productList[this.currentProductIndex].disposeReasonText = item.label;
      this.disposeReasonListShow = false;
    },
    onProductPropertyShow(index) {
      this.productPropertyListShow = true;
      this.currentProductIndex = index;
      this.$nextTick(() => {
        let value = this.productList[index].productProperty;
        let selectIndex = this.productPropertyList.findIndex(i => i.value === value);
        this.$refs.productPropertyList.setIndexes([selectIndex]);
      });
    },
    onProductPropertySelect(item) {
      if (this.productList[this.currentProductIndex].productProperty !== item.value) {
        let product = {
          productProperty: item.value
        };
        this.onProductListSelect(product);
      }
      this.productPropertyListShow = false;
    },
    onProductListShow(index) {
      this.currentProductIndex = index;
      let productProperty = this.productList[this.currentProductIndex].productProperty;
      if (!productProperty) {
        this.$toast('请先选择资产属性');
        return;
      }
      this.productListShow = true;
      this.onProductListSearch();
    },
    onProductListSearch() {
      let productProperty = this.productList[this.currentProductIndex].productProperty;
      if (!productProperty) {
        this.$toast('请先选择资产属性');
        return;
      }
      let params = {
        productProperty: productProperty,
        productNameProductNo: this.productListSearchKeyword,
        pageNum: this.productListSearchPageNum,
        pageSize: this.productListSearchPageSize
      };
      assetsApi.getStockProductList(params).then(res => {
        this.productListGets = res.data.data;
        this.productListSearchTotal = res.data.count;
      });
    },
    onProductListSearchPage() {
      this.onProductListSearch();
    },
    onProductListSelect(product) {
      let item = this.productList[this.currentProductIndex];
      item.stockId = product.id;
      item.productNo = product.productNo || '';
      item.productId = product.productId;
      item.productName = product.productName || '';
      item.standard = product.standards || '';
      item.unit = product.unit || '';
      item.actPrice = product.actPrice || 0;
      item.disposePrice = product.actPrice || 0;
      item.productProperty = product.productProperty;
      item.outSpaceFullCode = product.spaceFullCode || '';
      item.outSpaceName = product.spaceName || '';
      item.stockNum = product.count || 0;
      item.disposeNum = product.count && product.count > 0 ? 1 : 0;
      this.productListShow = false;
    },
    onAddItem() {
      this.productList.push({
        ...defaultProduct
      });
    },
    afterFileRead(item) {
      let up = this.$toast.loading({
        forbidClick: true,
        duration: 0
      });
      imageCompression(item.file, {
        maxSizeMB: 0.2,
        initialQuality: 0.7
      }).then(file => {
        assetsApi.fileUpload(item).then(res => {
          this.$toast.clear();
          const cb = res.data;
          if (cb.code === 200) {
            item.fileType = 1;
            item.url = cb.data.url;
            item.fileUrl = cb.data.url;
            item.status = 'success';
            item.message = '上传成功';
          } else {
            item.status = 'failed';
            item.message = '上传失败';
          }
        }).catch(e => {
          this.$toast.clear();
          item.status = 'failed';
          item.message = '上传失败';
        });
      }).catch(() => {
        this.$toast.clear();
        item.status = 'failed';
        item.message = '上传失败';
      });
    },
    onDelItem(index) {
      this.$dialog.confirm({
        message: '是否确认删除这条记录？'
      }).then(() => {
        this.productList.splice(index, 1);
      }).catch(() => {
        // on cancel
      });
    },
    onSubmit() {
      if (this.productList.length === 0) {
        this.$toast('请添加物资');
        return;
      } else if (this.productList.length) {
        for (let i = 0; i < this.productList.length; i++) {
          let item = this.productList[i];
          if (!item.productProperty) {
            this.$toast(`请选择第${i + 1}条明细的资产属性`);
            return;
          }
          if (!item.stockId) {
            this.$toast(`请选择第${i + 1}条明细的资产名称`);
            return;
          }
          if (!item.disposeNum) {
            this.$toast(`请设置第${i + 1}条明细的处置数量`);
            return;
          }
          if (!item.disposeReason) {
            this.$toast(`请选择第${i + 1}条明细的处置原因`);
            return;
          }
        }
      }
      this.$dialog.confirm({
        message: '是否确认提交？'
      }).then(() => {
        assetsApi.addDispose({
          projectId: this.projectId,
          projectName: this.projectName,
          disposePerson: this.disposePerson,
          disposeTotalPrice: this.disposeTotalPrice,
          remark: this.remark,
          disposeDetailVos: this.productList
        }).then(res => {
          this.$router.replace({
            name: 'AssetsDisposeSuccess'
          });
        });
      }).catch(() => {
        // on cancel
      });
    }
  }
};